<template>
      <div class="hero-section">
    <img src="@/assets/images/shanghai.jpg" alt="Landing Background" class="hero-image">
    <div class="content">
      <h1>HORIZONTAL SOURCING</h1>
      <p>YOUR HORIZONTAL SUPPLY CHAIN CONNECTION IN CHINA</p>
      <a href="#" class="btn">CONTACT US</a>
    </div>
  </div>
</template>

<script>
export default {
  name: "LandingSection",
};
</script>

<style scoped>
h1 {
  font-size: 48px;
  margin: 0;
}

p {
  font-size: 18px;
  margin: 20px 0;
  letter-spacing: 1px;
}

.hero-section {
  position: relative;
  width: 100vw; /* 使用视口宽度 */
  height: 100vh; /* 使用视口高度 */
  background-size: cover; /* 使用 cover 覆盖整个屏幕 */
  background-position: center; /* 图片居中 */
  background-repeat: no-repeat; /* 防止背景图片重复 */
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  overflow: hidden; /* 确保不会溢出容器 */
}

.hero-image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw; /* 使用 100vw 使宽度与视口宽度一致 */
  height: 100vh; /* 使用 100vh 使高度与视口高度一致 */
  object-fit: contain; /* 确保图片完整显示 */
  z-index: -1; /* 确保图片在内容后面 */
}

.content {
  position: relative;
  text-align: center;
  z-index: 1; /* 确保内容在叠加层上方 */
}

.btn {
  display: inline-block;
  padding: 10px 20px;
  margin-top: 20px;
  background-color: #d35400; /* 按钮背景颜色 */
  color: white;
  text-decoration: none;
  font-size: 16px;
  border-radius: 5px;
  transition: background-color 0.3s;
}

.btn:hover {
  background-color: #e67e22; /* 悬停时按钮颜色 */
}
</style>
