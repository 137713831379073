<template>
  <div class="aboutUs">
    <div class="contact-page">
      <div class="background-image">
        <div class="contact-info">
          <h1>工作联络</h1>
          <div class="info-group">
            <div class="info-item">
              <h2>WeChat</h2>
              <p>canvadesign</p>
            </div>
            <div class="info-item">
              <h2>Email Address</h2>
              <p>cnsupport@canva.com</p>
            </div>
            <div class="info-item">
              <h2>Phone Number</h2>
              <p>13066668888</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div>
      <form @submit.prevent="submitTicket" novalidate>
        <div>
          <label for="fullName">Full Name*</label>
          <input type="text" 
          v-model="form.fullName" 
          @input="clearFullNameError"  
          @blur="validateFullName"     
          required 
          />
          <span v-if="errors.fullName">{{ errors.fullName }}</span>
        </div>
        <div>
          <label for="email">Email*</label>
          <input type="email" 
          v-model="form.email" 
          @input="clearEmailError" 
          @blur="validateEmail" 
          required 
          />
          <span v-if="errors.email">{{ errors.email }}</span>
        </div>
        <div>
          <label for="country">Country/Region</label>
          <input type="text" v-model="form.country" />
        </div>
        
        <div>
          <label for="phone">Phone*</label>
          <input type="text" 
          v-model="form.phone" 
          @input="clearPhoneError" 
          @blur="validatePhone"
          required
          />
          <span v-if="errors.phone">{{ errors.phone }}</span>
        </div>
        <div>
          <label for="message">Message</label>
          <textarea v-model="form.message" required></textarea>
        </div>

        <!-- 隐私政策复选框 -->
      <div>
        <input type="checkbox" id="privacyCheck" v-model="form.acceptedPrivacy" />
        <label for="privacyCheck">
          To submit this form, you need to accept our
          <router-link to="/privacy-statement" class="btn">Privacy Statement</router-link>
        </label>
      </div>
      <span v-if="errors.acceptedPrivacy" class="error">{{ errors.acceptedPrivacy }}</span>
        <button type="submit">Submit</button>
      </form>
    </div>
</template>

<script>
export default {
  name: "ContactSection",
  data() {
    return {
      form: {
        fullName: '',
        email: '',
        country: '',
        phone: '',
        message: '',
        acceptedPrivacy: false, // 添加复选框的状态
      },
      errors: {
        fullName: '',
        email: '',
        phone: '',
        acceptedPrivacy: '', // 复选框的错误信息
      },
    };
  },
  
  methods: {
    clearFullNameError() {
      this.errors.fullName = '';
    },
    clearEmailError() {
      this.errors.email = '';
    },
    clearPhoneError() {
      this.errors.phone = '';
    },


    validateFullName() {
      if (!this.form.fullName) {
        this.errors.fullName = 'Full Name is required.';
      } else {
        this.errors.fullName = '';
      }
    },
    validateEmail() {
      const emailPattern = /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/;
      if (!emailPattern.test(this.form.email)) {
        this.errors.email = 'Please enter a valid email address';
      } else {
        this.errors.email = '';
      }
    },
    validatePhone() {
  const phonePattern = /^\d*$/;
  if (!this.form.phone) {
    this.errors.phone = 'Phone number is required.'; // 确保处理空值的情况
  } else if (!phonePattern.test(this.form.phone)) {
    this.errors.phone = 'Please enter a valid phone number';
  } else {
    this.errors.phone = '';
  }
},

    validatePrivacy() {
      if (!this.form.acceptedPrivacy) {
        this.errors.acceptedPrivacy = 'You must accept the Privacy Statement to submit the form.';
      } else {
        this.errors.acceptedPrivacy = '';
      }
    },

    
    submitTicket() {
      this.validateFullName();
      this.validateEmail();
      this.validatePhone();
      this.validatePrivacy();

      if (this.errors.fullName || this.errors.email || this.errors.phone || this.errors.acceptedPrivacy) {
        alert('Please correct the errors before submitting.');
        return;
      }
      
      // 使用 fetch 将表单数据发送到后端
      fetch('https://horizontalsourcing-16207d685b98.herokuapp.com/submit-ticket', {
        method: 'POST',
  headers: {
    'Content-Type': 'application/json',
  },
  body: JSON.stringify({
    fullName: this.form.fullName,
    email: this.form.email,
    country: this.form.country,
    phone: this.form.phone,
    message: this.form.message
  }),
})
  .then(response => response.json())
  .then(data => {
    alert(data.message); // 显示提交成功的消息
  })
  .catch(error => {
  console.error('Error:', error);
  alert('Failed to submit the form. Please try again later.');
});
    },
  },
};
</script>

<style scoped>
input, textarea {
  border: 1px solid #ccc;
  padding: 5px;
  margin-bottom: 10px;
}

span {
  color: red;
  font-size: 0.9em;
}

.contact-page {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background: url('@/assets/images/shanghai.jpg') no-repeat center center;
  background-size: cover;
  color: white;
  text-align: center;
}

.contact-info {
  background-color: rgba(0, 0, 0, 0.5);
  padding: 20px;
  border-radius: 8px;
}

.info-group {
  display: flex;
  justify-content: space-around;
  margin-top: 20px;
}

.info-item h2 {
  font-size: 1.5em;
  margin: 0;
}

.info-item p {
  font-size: 1.2em;
  margin: 5px 0;
}

/*Formrelated css*/
.error {
  color: red;
  font-size: 0.9em;
  margin-top: 4px;
}

.required {
  color: red;
  font-weight: bold;
}

.form-group {
  margin-bottom: 20px;
}
</style>
