
<template>
    <div :class="['header', { 'scrolled': scroll > 50 }]"> <!-- 修改：动态绑定类 -->
    <!-- Logo Section -->
    <div class="CompLogo" :style="{ opacity: logoOpacity }"> <!-- 动态绑定透明度 -->
      <router-link to="/">
          <img src="@/assets/images/sampleCompLogo.jpg" alt="Company logo" class="logo" />
        </router-link>
      </div>
      <!-- Navigation Links Section -->
      <nav class="header-nav">
        <ul>
          <li><router-link :to="{ path: '/', hash: '#home' }">{{ $t('navigation.home') }}</router-link></li>
          <li><router-link :to="{ path: '/', hash: '#about' }">{{ $t('navigation.about') }}</router-link></li>
          <li><router-link :to="{ path: '/', hash: '#services' }">{{ $t('navigation.services') }}</router-link></li>
          <li><router-link :to="{ path: '/', hash: '#contact' }">{{ $t('navigation.contact') }}</router-link></li>
        </ul>
        
      </nav>
      <!-- Language Selector Section -->
      <div class="language-selector">
        <div class="current-language" @click="toggleDropdown">
          <span>{{ currentLanguageLabel }}</span>
          <i class="arrow-down"></i>
        </div>
        <ul :class="{'language-dropdown': true, 'show': dropdownVisible}" class="language-dropdown">
          <li @click="changeLanguage('zh')">中文</li>
          <li @click="changeLanguage('en')">English</li>
        </ul>
      </div>
    </div>
</template>

<script>
import { ref, computed, onMounted, onUnmounted } from 'vue';
import { useLanguageStore } from '@/stores/languageStore';

export default {
  name: 'HeaderComponent',
  setup() {
    const languageStore = useLanguageStore();
    const dropdownVisible = ref(false);
    const scroll = ref(0);
    const path = ref(window.location.pathname);

    const currentLanguageLabel = computed(() =>
      languageStore.currentLocale === 'en' ? 'English' : '中文'
    );

    const toggleDropdown = () => {
      dropdownVisible.value = !dropdownVisible.value;
    };

    const changeLanguage = (lang) => {
      if (languageStore.currentLocale !== lang) {
        languageStore.toggleLanguage();
      }
      dropdownVisible.value = false;
    };

    const updateScroll = () => {
      scroll.value = window.scrollY;
    };

    const logoOpacity = computed(() => {
      return scroll.value > 10 ? 1 : 0; // 当滚动超过50px时，透明度变为1
    });

    onMounted(() => {
      window.addEventListener('scroll', updateScroll);
    });

    onUnmounted(() => {  // 确保在组件卸载时移除监听器
      window.removeEventListener('scroll', updateScroll);
    });

    return { currentLanguageLabel, toggleDropdown, changeLanguage, dropdownVisible, scroll, path, logoOpacity };
  },
};
</script>

<style scoped>
.header {
  width: 100%;
  z-index: 1000;
  position: fixed;
  top: 0;
  left: 0;
  transition: all 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
  display: flex; /* 使用flex布局 */
  align-items: center; /* 垂直居中 */
  justify-content: space-between; /* 在主轴上分布 */
  background-color: transparent; /* 背景色，您可以根据需要调整 */
  box-shadow: none;
}

.header.scrolled {  /* 新增：滚动状态 */
  background-color: #fff; /* 向下滚动时背景色变为白色 */
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1); /* 添加阴影效果 */
}

.CompLogo {
  flex: 1; /* Logo占据1份空间 */
  opacity: 0; /* 初始状态设置为透明 */
  transition: opacity 0.3s ease; /* 添加渐变过渡效果 */
}

.CompLogo img {
  width: 120px;
  height: auto;
  transition: transform 0.3s;
}

.header-nav {
  flex: 3; /* Navigation占据5份空间 */
  text-align: center; /* 居中对齐 */
  padding-left: 30%;
}

.header-nav ul {
  display: flex;
  list-style: none;
  padding-right: 40px;
  margin: auto;
  justify-content: center; /* 将导航项居中 */
}

.header-nav li {
  margin: 0 40px; /* 控制间距 */

}

.header-nav li a {
  color: #333;
  text-decoration: none;
  font-weight: bold;
  transition: transform 0.5s ease, color 0.5s ease;
}

.header-nav li a:hover {
  color: #ff0000;
  transform: scale(1.1);
}

.language-selector {
  flex: 0.5; /* 语言选择器占据0.5份空间 */
  text-align: right; /* 右对齐 */
  position: relative;
  font-size: 14px;
  color: #333;
}

.current-language {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.arrow-down {
  border: solid #333;
  border-width: 0 2px 2px 0;
  display: inline-block;
  padding: 3px;
  transform: rotate(45deg);
  transition: transform 0.3s;
  margin: 5px;
}

.language-dropdown {
  position: absolute;
  top: 100%;
  left: 0;
  background-color: #fff;
  border-radius: 5px;
  box-shadow: 0 2px 5px rgba(43, 38, 38, 0.1);
  margin-top: 5px;
  list-style: none;
  padding: 0;
  width: 70px;
  z-index: 100;
  max-height: 0;
  overflow: hidden;
  opacity: 0;
  transition: max-height 0.3s ease, opacity 0.3s ease;
}

.language-dropdown.show {
  max-height: 200px;
  opacity: 1;
}

.language-dropdown li {
  padding: 8px 12px;
  cursor: pointer;
  text-align: center;
}

@media (max-width: 768px) {
  .header {
    flex-direction: column;
    align-items: center;
  }

  .header-nav ul {
    flex-direction: column;
    margin-top: 10px;
  }

  .header-nav li {
    margin: 10px 0;
  }
}
</style>
