// src/i18n.js
import { createI18n } from 'vue-i18n';
import en from './locales/en_US.json'; // 静态导入默认语言
import zh from './locales/zh.json'

const messages = {
  en, // 默认语言
  zh,
};

const i18n = createI18n({
  locale: 'en', // 默认语言
  fallbackLocale: 'en', // 回退语言
  messages, // 语言消息
  globalInjection: true, // 全局注入
  legacy: false, // 使用 Composition API 方式
});

// 动态加载语言文件
async function loadLocaleMessages(locale) {
  if (!messages[locale]) {
    const localeData = await import(`./locales/${locale}.json`);
    i18n.global.setLocaleMessage(locale, localeData.default);
  }
}

export { i18n, loadLocaleMessages };
export default i18n;
