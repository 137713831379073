<template>
    <div class="privacy-statement-page">
      <div class="container">
        <h1>Privacy Statement</h1>
        <p>Last updated: September 17, 2024</p>
  
        <section class="section">
          <h2>Introduction</h2>
          <p>
            This Privacy Statement explains how [Your Company Name] collects, uses, and protects your personal information. We are committed to protecting your privacy and ensuring the security of your personal information. Please read this statement carefully to understand our practices regarding your personal data.
          </p>
        </section>
  
        <section class="section">
          <h2>Information We Collect</h2>
          <p>We may collect the following types of information from you:</p>
          <ul>
            <li>Personal Identification Information (e.g., name, email address, phone number)</li>
            <li>Demographic Information (e.g., location, preferences)</li>
            <li>Technical Data (e.g., IP address, browser type, operating system)</li>
          </ul>
        </section>
  
        <section class="section">
          <h2>How We Use Your Information</h2>
          <p>We use the information we collect for the following purposes:</p>
          <ul>
            <li>To provide, operate, and maintain our website and services</li>
            <li>To improve and personalize your experience on our website</li>
            <li>To communicate with you, including customer service, support, and updates</li>
            <li>To analyze website traffic and user behavior for improving our services</li>
            <li>To comply with legal obligations and prevent fraud or abuse</li>
          </ul>
        </section>
  
        <section class="section">
          <h2>Sharing Your Information</h2>
          <p>
            We may share your information with third-party service providers to help us operate our business, such as payment processors, email service providers, and analytics tools. We ensure these third parties have appropriate data protection measures in place.
          </p>
        </section>
  
        <section class="section">
          <h2>Your Rights</h2>
          <p>
            You have certain rights regarding your personal data, including the right to access, correct, delete, or restrict the use of your data. You may also have the right to object to processing and request data portability.
          </p>
          <p>
            To exercise these rights, please contact us at <a href="mailto:privacy@yourcompany.com">privacy@yourcompany.com</a>.
          </p>
        </section>
  
        <section class="section">
          <h2>Data Security</h2>
          <p>
            We take data security seriously and have implemented measures to protect your personal information from unauthorized access, disclosure, alteration, and destruction. However, no method of transmission over the Internet or electronic storage is 100% secure.
          </p>
        </section>
  
        <section class="section">
          <h2>Changes to This Privacy Statement</h2>
          <p>
            We may update this Privacy Statement from time to time. Any changes will be posted on this page with an updated date. We encourage you to review this page periodically to stay informed about how we protect your information.
          </p>
        </section>
  
        <section class="section">
          <h2>Contact Us</h2>
          <p>
            If you have any questions or concerns about this Privacy Statement or our data practices, please contact us at:
          </p>
          <p>Email: <a href="mailto:privacy@yourcompany.com">privacy@yourcompany.com</a></p>
          <p>Phone: +123 456 7890</p>
        </section>
      </div>
    </div>
  </template>
  
  <style scoped>
  .privacy-statement-page {
    padding: 20px;
    background-color: #f9f9f9;
    font-family: 'Arial', sans-serif;
    color: #333;
  }
  
  .container {
    max-width: 800px;
    margin: 0 auto;
    background-color: #fff;
    padding: 30px;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  }
  
  h1, h2 {
    color: #2c3e50;
  }
  
  h1 {
    margin-bottom: 10px;
  }
  
  h2 {
    margin-top: 20px;
  }
  
  p {
    margin-bottom: 15px;
    line-height: 1.6;
  }
  
  ul {
    list-style-type: disc;
    margin-left: 20px;
    margin-bottom: 15px;
  }
  
  a {
    color: #e74c3c;
    text-decoration: none;
  }
  
  a:hover {
    text-decoration: underline;
  }
  </style>
  