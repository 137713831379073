// src/router/index.js
import { createRouter, createWebHistory } from 'vue-router';
import IndexPage from '../pages/Index/IndexPage.vue';
import PrivacyStatement from '@/pages/Others/PrivacyStatement.vue';

const routes = [
  {
    path: '/',
    name: 'index',
    component: IndexPage,
  },

  {
    path: '/privacy-statement',
    name: 'PrivacyStatement',
    component: PrivacyStatement,
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior(to) {
    if (to.hash) {
      return {
        el: to.hash,
        behavior: 'smooth', // 立即滚动到顶部

      };
    } else {
      return { top: 0 };
    }
  }  
});

export default router;
