<template>
  <footer class="footer">
    <div class="footer-content">
      <!-- 左侧：二维码图片部分 -->
      <div class="footer-section1">
        <img src="@/assets/images/sample1.jpg" alt="微信公众号" class="qrcode" />
        <p>- XXXX -</p>
      </div>
      <div class="footer-section2">
        <img src="@/assets/images/sample1.jpg" alt="湘鹏小程序" class="qrcode" />
        <p>- XXXX -</p>
      </div>

      <!-- 中间：公司信息 -->
      <div class="footer-section3">
        <h4>{{ $t("footer.information") }}</h4>
        <p>{{ $t("footer.name") }}</p>
        <p>{{ $t("footer.Hours") }}</p>
      </div>

      <!-- 右侧：联系方式 -->
      <div class="footer-section4">
        <h4>{{ $t("footer.contact") }}</h4>
        <p>{{ $t("footer.phone") }}</p>
        <p>{{ $t("footer.address") }}</p>
      </div>
    </div>

    <!-- 底部：版权信息 -->
    <div class="footer-bottom">
      <p>&copy; {{ $t("footer.company") }}</p>
      <p>
        <a href="#">{{ $t("footer.termOfservice") }}</a> | <a href="#">隐私保护</a>
      </p>
    </div>
  </footer>
</template>

<script>
export default {
  name: 'FooterComponent',
};
</script>

<style scoped>
.footer {
  background-color: #f26722;
  color: #000000;
  padding: 20px;
  text-align: center;
  font-size: 14px;
}

.footer-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.footer-section1 {
  flex: 1;
  text-align: center;
  
}

.footer-section2 {
  flex: 1;
  text-align: center;
}

.footer-section3 {
  flex: 1;
  margin: 0 10px;
  text-align: left;
}

.footer-section4 {
  flex: 1;
  margin: 0 10px;
  text-align: left;
}

.qrcode {
  width: 100px; /* 二维码图片宽度 */
  height: 100px; /* 二维码图片高度 */
  margin-bottom: 10px;
}

.footer-bottom {
  border-top: 1px solid #444;
  padding-top: 10px;
  font-size: 12px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.footer-bottom a {
  color: #ffffff;
  text-decoration: none;
  margin: 0 5px;
}

.footer-bottom a:hover {
  text-decoration: underline;
}
</style>
