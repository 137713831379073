<!-- src/pages/AboutPage.vue -->
<template>
        <div class="aboutUs">
      <div class="left-section">
        <img src="@/assets/images/teamwork.jpg" class="outlimage">
      </div>
      <div class="right-section">
        <h2>了解我们</h2>
        <ul class="navigation-list">
          <li><a href="#">我们的价值观</a></li>
          <li><a href="#">团队介绍</a></li>
          <li><a href="#">服务内容</a></li>
          <li><a href="#">公司简报</a></li>
          <li><a href="#">预约咨询</a></li>
          <li><a href="#">联系我们</a></li>
        </ul>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    name: 'AboutSection',
  };
  </script>
  
  <style scoped>
.aboutUs-section {
  width: 100%; /* 使 section 占据整个宽度 */
  padding: 120px 0; /* 给 section 添加一些上下间距 */
  background-color: #ffffff; /* 设置背景颜色 */
}


.aboutUs {
  display: flex; /* 使用 flex 布局 */
  justify-content: center; /* 子元素居中对齐 */
  align-items: center; /* 子元素垂直居中对齐 */
  max-width: 1200px; /* 设置一个最大宽度，防止在大屏幕上太宽 */
  margin: 0 auto; /* 水平居中对齐 */
}


.left-section,
.right-section {
  width: 50%; /* 左右部分各占50%宽度 */
  height: 100%; /* 使两部分高度一致 */
}

.outlimage {
  width: 100%; /* 图片宽度占满父元素 */
  height: 100%; /* 图片高度占满父元素 */
}

.left-section {
  position: relative;
  width: 60%; /* 左侧部分占据60%宽度 */
  flex: 2;
}

.right-section {
  background-color: #f26722; /* 背景颜色 */
  display: flex;
  flex-direction: column;
  justify-content: center; /* 内容垂直居中 */
  align-items: center;
  padding: 40px;
  color: white;
}

h2 {
  font-size: 24px;
  margin-bottom: 20px;
}
  </style>
  