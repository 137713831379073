<template>
  <HeaderComponent /> <!-- 全局头部组件 -->
  <router-view />
  <FooterComponent /> <!-- 全局底部组件 -->
</template>



<script>
import FooterComponent from './components/footerComponent/footerComponent.vue';
import HeaderComponent from './components/headerComponent/headerComponent.vue';


export default {
  name: 'App',
  components: {
    HeaderComponent,
    FooterComponent
  }
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 0px;
  padding-top: 0px; 
  padding-bottom: 0px; 
}

#HeaderComponent{
  padding: 0px; 
}

.animate__delay-1s {
  animation-duration: 1s;
}
.animate__delay-2s {
  animation-duration: 2s;
}
.animate__delay-3s {
  animation-duration: 3s;
}
.animate__delay-5s {
  animation-duration: 5s;
}

@keyframes fadeInLeft {
  from {
    opacity: 0;
    transform: translateX(-100%);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes fadeInRight {
  from {
    opacity: 0;
    transform: translateX(100%);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
}

</style>
