<template>
  <div class="projects-section">
    <h1>参与项目</h1>
    <div class="project-grid">
      <div class="project-item">
        <i class="icon icon-people"></i>
        <h2>项目1</h2>
        <p>网站是一种通讯工具，就像布告栏一样，人们可以通过网站来发布自己想要公开的资讯。</p>
      </div>
      <div class="project-item">
        <i class="icon icon-computer"></i>
        <h2>项目2</h2>
        <p>网站是一种通讯工具，就像布告栏一样，人们可以通过网站来发布自己想要公开的资讯。</p>
      </div>
      <div class="project-item">
        <i class="icon icon-facebook"></i>
        <h2>项目3</h2>
        <p>网站是一种通讯工具，就像布告栏一样，人们可以通过网站来发布自己想要公开的资讯。</p>
      </div>
      <div class="project-item">
        <i class="icon icon-instagram"></i>
        <h2>项目4</h2>
        <p>网站是一种通讯工具，就像布告栏一样，人们可以通过网站来发布自己想要公开的资讯。</p>
      </div>
      <div class="project-item">
        <i class="icon icon-pinterest"></i>
        <h2>项目5</h2>
        <p>网站是一种通讯工具，就像布告栏一样，人们可以通过网站来发布自己想要公开的资讯。</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ServicePage",
};
</script>

<style scoped>
.projects-section {
  text-align: center;
  padding: 40px;
  background-color: #f8f8f8; /* Use the appropriate background color */
}

.projects-section h1 {
  font-size: 3em;
  margin-bottom: 40px;
}

.project-grid {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  gap: 20px;
}

.project-item {
  background: white;
  padding: 20px;
  width: 200px;
  border-radius: 10px;
  text-align: center;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.project-item i {
  font-size: 3em;
  color: #e66b2b; /* Use the appropriate icon color */
  margin-bottom: 15px;
}

.project-item h2 {
  font-size: 1.5em;
  margin: 0 0 10px;
}

.project-item p {
  font-size: 1em;
  color: #666;
}
</style>
